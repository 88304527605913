import "./Banner.scss";

export default function Banner() {
    return (
        <div className="banner-container">
            <div className="main-container">
                <div className="banner-content">
                    <h1>LED Light Products <span>For Your Space</span></h1>
                    <p>Whether you need lighting repairs, electrical upgrades, or a new installation, we’re here to provide quality services that meet your needs and exceed your expectations.</p>
                </div>
            </div>
        </div>
    )
}