import "./Features.scss";
import { RiLightbulbFlashFill } from "react-icons/ri";
import { FaSackDollar } from "react-icons/fa6";
import { ImPower } from "react-icons/im";
import { FaTools } from "react-icons/fa";

export default function Features() {
  const features = [
    {
      icon: <RiLightbulbFlashFill className="feature-icon"/>,
      text: "Wide Range Of LED Products",
    },
    {
      icon: <FaSackDollar className="feature-icon" />,
      text: "Brightest Lights At Affordable Price",
    },
    {
      icon: <ImPower className="feature-icon icon-bulb" />,
      text: "Less Power Consumption",
    },
    {
      icon: <FaTools className="feature-icon icon-tools" />,
      text: "High-quality tools",
    },
  ];

  return (
    <div className="features-container">
      <div className="main-container">
        <div className="features-row">
          {features?.map((item) => (
            <div className="features-list">
              {item?.icon}
              <h5 className="feature-content">{item?.text}</h5>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
