import "./Products.scss";
import troffers from "../../img/troffers.webp";
import ledStreetLights from "../../img/led-street-lights.webp";
import ledTubelights from "../../img/led-tubelights.webp";
import surfaceAmbient from "../../img/surface-ambient.webp";
import ledBulb from "../../img/led-bulb.webp";
import ledLights from "../../img/led-lights.webp";
import ledPanelLights from "../../img/led-panel-lights.webp";
import ledFloodLights from "../../img/led-flood-lights.webp";

export default function Products() {
  return (
    <section className="products-container">
      <div className="main-container">
        <h6>OUR PRODUCTS</h6>
        <h2>High Quality LED Lights</h2>
        <div className="products-row">
          <div className="products-col">
            <img src={ledBulb} alt="led bulb" />
            <h5>LED Bulb</h5>
          </div>
          <div className="products-col">
            <img src={ledTubelights} alt="led tubelights" />
            <h5>LED Tube Lights</h5>
          </div>
          <div className="products-col">
            <img src={ledStreetLights} alt="led Street lights" />
            <h5>LED Street Lights</h5>
          </div>
          <div className="products-col">
            <img src={ledLights} alt="led lights" />
            <h5>LED Lights</h5>
          </div>
          <div className="products-col">
            <img src={ledPanelLights} alt="led panel lights" />
            <h5>LED Panel Lights</h5>
          </div>
          <div className="products-col">
            <img src={ledFloodLights} alt="led Flood lights" />
            <h5>LED Flood Lights</h5>
          </div>
          <div className="products-col">
            <img src={troffers} alt="Troffers" />
            <h5>Troffers</h5>
          </div>
          <div className="products-col">
            <img src={surfaceAmbient} alt="Surface Ambient" />
            <h5>Surface Ambient</h5>
          </div>
        </div>
      </div>
    </section>
  );
}
