import "./Footer.scss";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLocationDot,
  FaPhone,
  FaEnvelope,
} from "react-icons/fa6";
import logoWhite from "../../img/logo-white.png";

export default function Footer() {
  return (
    <footer>
      <div className="main-container">
        <div className="contact-us-block">
          <div className="col-1">
            <img src={logoWhite} alt="AETS" className="footer-logo" />
            <p>
              Our team can assist with the installation and integration of home
              automation systems, allowing you to control your lighting and
              other electronic devices with ease, can provide backup generator
              installation and maintenance services.
            </p>
            <ul class="social-media-list">
              <li>
                <a href="#" target="_self">
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a href="#" target="_self">
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a href="#" target="_self">
                  <FaTwitter />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-2">
            <h5>Explore</h5>
            <ul className="menu-footer">
              <li>
                <a href="http://wolt.like-themes.com/blog-masonry-with-sidebar/">
                  About Us
                </a>
              </li>
              <li>
                <a href="http://wolt.like-themes.com/shop/">Products</a>
              </li>
              <li>
                <a href="http://wolt.like-themes.com/faq/">Services</a>
              </li>
              <li>
                <a href="http://wolt.like-themes.com/gallery-4-columns/">
                  Gallery
                </a>
              </li>
              <li>
                <a href="http://wolt.like-themes.com/contacts/">Contacts</a>
              </li>
            </ul>
          </div>
          <div className="col-3">
            <h5>Contact Us</h5>
            <ul class="footer-address">
              <li>
                <FaLocationDot className="footer-icon" />
                <span class="footer-icon-content">
                  <div class="icon-header"> Address: </div>
                  <div class="icon-descr">
                  Andaman Electro-Tech Solution <br/>
                    32, MA Rd, Phoenix Bay,<br/> Port Blair, A & N Is. - 744102</div>
                </span>
              </li>
              <li>
                <FaPhone className="footer-icon" />
                <div class="footer-icon-content">
                  <div class="icon-header"> Phone: </div>
                  <div class="icon-descr">+91 9734483676</div>
                </div>
              </li>
              <li>
                <FaEnvelope className="footer-icon" />
                <div class="footer-icon-content">
                  <div class="icon-header"> Email: </div>
                  <div class="icon-descr"><a href="mailto:mondal.asit676@gmail.com">mondal.asit676@gmail.com</a></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="copyright-block">
          <p>&#169; {new Date().getFullYear()} - AETS. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}
