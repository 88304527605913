import "./About.scss";
import aboutImg from "../../img/about-us.jpg";

export default function About() {
  return (
    <section className="about-container">
      <div className="main-container">
        <div className="about-image-container">
            <img src={aboutImg} alt="worker" />
            <div className="experience-text">
                15 <span>Years Of Experience</span>
            </div>
        </div>
        <div className="about-content">
          <h6>ABOUT COMPANY</h6>
          <h2>We are reliable and experienced</h2>
          <p>
            Let no darkness ever dull your light; always buy the best products
            for lightening purposes. We, at AETS, deal in with the best
            LED lights for every purpose at an affordable price so that you
            don’t face any hurdles due to the lack of bright light. Located at
            area, city, state, we are uniquely positioned to innovate new ways
            in which lighting will serve as a platform for emerging technologies
            and capabilities that will enrich lives, improve society and
            safeguard our planet. We are committed to magnifying customers’
            satisfaction and that belief has helped us garner a vast clientele,
            which continues to grow everyday.
          </p>
        </div>
      </div>
    </section>
  );
}
