import { useState, useEffect } from "react";
import "./ContactForm.scss";
import bulbNoBlink from "../../img/bulb-noblink.png";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";

export default function ContactForm() {
  const [contactSuccess, setContactSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      emailId: "",
      phoneNumber: "",
      message: "",
    },
  });
  const onSubmit =  (data) => {
    const serviceId = "service_90lfeci";
    const templateId = "template_2ngzfry";
    setLoading(true);
    try {
      emailjs.send(serviceId, templateId, {
        to_name: "Asit Mondal",
        from_name: `${data?.name} - (${data?.emailId})`,
        message: data?.message,
        phone_number: data?.phoneNumber
      });
      setTimeout(()=> {
        setLoading(false);
        setContactSuccess(true);
        reset(); 
      }, 2000);

    } catch (error) {
      setContactSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => emailjs.init("0NkGxxCQ2Rl1Ep4TP"), []);

  return (
    <section className="contact-form-container">
      <div className="main-container contact-form-row">
        <div className="col-1">
          <h2>Making Bright<br/>Moments Brighter</h2>
          <img src={bulbNoBlink} alt="bulb" className="img-bulb" />
        </div>
        <div className="col-2">
          <h4>Get in touch</h4>
          {contactSuccess ? (
            <div className="thank-you-container">
              <div className="success-icon">
                <i className="fa fa-check-circle" aria-hidden="true"></i>
              </div>
              <h3>
                  Thank you for requesting a call back from AETS. Our team has received your information and will be in touch with you shortly. We look forward to speaking with you at your preferred date and time to discuss your specific needs and provide the assistance you require. We appreciate your interest and the opportunity to serve you.
              </h3>
              <a
                onClick={()=>setContactSuccess(false)}
                className="btn btn-outline-primary"
                rel="noopener noreferrer"
                >
                Close
              </a>
            </div>
          ) : (
            <>
              <p>Get a free consultation with an electrical engineer.</p>
              <form
                className="section-form"
                name="Contact Form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <input
                  name="name"
                  {...register("name", {
                    required: true,
                    pattern: /^[a-zA-Z ]+$/i,
                  })}
                  placeholder="Name*"
                  className="form-content"
                />
                <div className="error-msg">
                  {errors.name?.type === "required" && (
                    <p>Name is required</p>
                  )}
                  {errors.name?.type === "pattern" && (
                    <p>Please enter valid Name</p>
                  )}
                </div>

                <input
                  name="emailId"
                  {...register("emailId", {
                    required: true,
                    pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i,
                  })}
                  placeholder="Email Address*"
                  className="form-content"
                />
                <div className="error-msg">
                  {errors.emailId?.type === "required" && (
                    <p>Email Address is required</p>
                  )}
                  {errors.emailId?.type === "pattern" && (
                    <p>Please enter your Business Email Address</p>
                  )}
                </div>

                <input
                  name="phoneNumber"
                  {...register("phoneNumber", {
                    required: true,
                    pattern: /^[0-9\-+().\s]{8,20}$/,
                  })}
                  placeholder="Phone Number*"
                  className="form-content"
                />
                <div className="error-msg">
                  {errors.phoneNumber?.type === "required" && (
                    <p>Phone Number is required</p>
                  )}
                  {errors.phoneNumber?.type === "pattern" && (
                    <p>Please enter valid phone number</p>
                  )}
                </div>

                <textarea
                  name="message"
                  {...register("message", {
                    required: true,
                  })}
                  placeholder="Message*"
                  className="form-content"
                  rows="4"
                />
                <div className="error-msg">
                  {errors.message?.type === "required" && (
                    <p>Message is required</p>
                  )}
                </div>
                <button
                      disabled={loading}
                      type="submit"
                      className="btn btn-primary"
                    >
                      {loading ? "Sending..." :
                      "Send Message" }
                    </button>
              </form>
            </>
          )}
        </div>
      </div>
    </section>
  );
}
